import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "F:/web/active/infoamazonia-mercurio/src/components/reports/report-layout.js";
import Image from "!url-loader!../../images/ouro.jpg";
export const meta = {
  name: "gold",
  title: "Tracing Tainted Gold",
  intro: "An investigation of mercury traﬃcking networks in the Amazon reveals how Venezuelan gold is laundered into legitimate supply chains and could end up in products made by the world’s biggest corporations.",
  by: "By Bram Ebus (text and photos) Videos by Tom Laffay",
  image: Image
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Gallery = makeShortcode("Gallery");
const Sidenote = makeShortcode("Sidenote");
const layoutProps = {
  meta,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`Informality and corruption`}</strong>{` prevail in the Guiana Shield region, where illegal gold mining greases the wheels of its jungle economies. In Guyana, police officers are seen with bigger golden necklaces and wristbands than the miners themselves, and some villages’ convenience stores label the price of basic products in grams of gold.`}</p>
    <p>{`The industry is so powerful that it fuels a multi-million dollar shadow industry of mercury. An investigation of the dynamics of the formal and informal mercury trade in the Guiana Shield region found that gold mined in Guyana, Suriname, and even Venezuela flows along some of the same trafficking routes. Complex networks move their riches through the interior jungles, across uncontrolled borders and through major ports on the Northern Atlantic Ocean.`}</p>
    <p>{`The northeastern Amazon has become one of the key transit points for illegal Venezuelan gold, as alleged by multiple gold traders, traffickers, and law enforcement sources. They claim the gold is trafficked across the region and scrubbed of its origins, eventually making its way into international markets and the products of some of the world’s largest multinational corporations.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAc1qmZtUmH//xAAbEAADAAIDAAAAAAAAAAAAAAAAAhEBAxITIv/aAAgBAQABBQJJPMzgRoNt5HZD/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRABAAMBAQAAAAAAAAAAAAAAABEhMQEC/9oACAEBAAY/Ar9O2pjEQ//EABsQAQACAgMAAAAAAAAAAAAAAAEAESExQWHR/9oACAEBAAE/IU3ZfFMEBYxUyZKQdO+4w9QppH//2gAMAwEAAgADAAAAEOTv/8QAFhEBAQEAAAAAAAAAAAAAAAAAABFh/9oACAEDAQE/EE1//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxBqmn//xAAdEAEAAgIDAQEAAAAAAAAAAAABESEAMUFhoZHh/9oACAEBAAE/EAwoGCWzRlp5BMsN+4y1ZyS4lFA7S00eYxEnP09d4gUDV/mf/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/c5599e2a970ec1f0de283d5381422f53/e93cc/Guyana-35.webp 300w", "/static/c5599e2a970ec1f0de283d5381422f53/b0544/Guyana-35.webp 600w", "/static/c5599e2a970ec1f0de283d5381422f53/68fc1/Guyana-35.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/c5599e2a970ec1f0de283d5381422f53/73b64/Guyana-35.jpg 300w", "/static/c5599e2a970ec1f0de283d5381422f53/3ad8d/Guyana-35.jpg 600w", "/static/c5599e2a970ec1f0de283d5381422f53/6c738/Guyana-35.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/c5599e2a970ec1f0de283d5381422f53/6c738/Guyana-35.jpg",
            "alt": "In some villages’ convenience stores label the price of basic products in grams of gold.",
            "title": "In some villages’ convenience stores label the price of basic products in grams of gold.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`In some villages’ convenience stores label the price of basic products in grams of gold.`}</em></p>
    <blockquote>
      <p parentName="blockquote">{`Gold is often mined illegally in Guyana and Suriname, but does not share the violence that surrounds mining in Venezuela`}</p>
    </blockquote>
    <p>{`Gold is often mined illegally in Guyana and Suriname, but does not share the violence that surrounds mining in Venezuela, where illegal mines are controlled by corrupt army officials, organized crime syndicates, and Colombian rebel groups. The gold industry in Venezuela is rife with human rights abuses and environmental violations, and since the creation of the Orinoco Mining Arc in 2016, a112,000-square-kilometer area in Venezuela destined for mining development, Venezuelan gold has faced increasing international pressure.`}</p>
    <p><strong parentName="p">{`The Orinoco Minning Arc`}</strong></p>
    <iframe height="480" src="https://infoamazonia.org/pt/embed/?map_only=1&map_id=17448&width=960&height=480"></iframe>
    <p>{`>`}{`>`}{` `}<strong parentName="p">{`In March 2019`}</strong>{`, the United States Treasury Department’s Office of Foreign Assets Control (OFAC) sanctioned state mining company Minerven and its director, arguing that the gold sector gives the “Maduro regime” a lifeline to stay in power. “We will aggressively pursue those involved with Maduro’s reckless illicit gold trade which is contributing to this financial, humanitarian, and environmental crisis,” U.S. Treasury Secretary Steven Mnuchin said in `}<a parentName="p" {...{
        "href": "https://home.treasury.gov-/news/press-releases/sm631"
      }}>{`a statement`}</a>{`.`}</p>
    <p>{`>`}{`>`}{` `}<strong parentName="p">{`In July 2020`}</strong>{`, the European Parliament called for an immediate ban on the trade and circulation of Venezuelan gold. “`}{`[`}{`T]his so-called blood gold is extracted and exploited at the expense of human rights and the environment under illegal and criminal conditions which seriously threaten both,” `}<a parentName="p" {...{
        "href": "https://www.europarl.europa.eu/doceo/document/TA-9-2020-0193_EN.html"
      }}>{`the resolution said`}</a>{`.`}</p>
    <p>{`>`}{`>`}{` `}<strong parentName="p">{`Also in July 2020`}</strong>{`, the United Nations Human Rights Office published `}<a parentName="p" {...{
        "href": "https://www.ohchr.org/EN/HRBodies/HRC/RegulSession44/Documents/A_HRC_44_54.pdf"
      }}>{`a report`}</a>{` condemning criminal activities and grave human rights abuses in Venezuelan mining regions. “Authorities should take immediate steps to end labour and sexual exploitation, child labour and human trafficking, and should dismantle criminal groups controlling mining activities. They must also investigate, prosecute and punish those responsible for human rights violations, abuses and crimes,” Michelle Bachelet, U.N. High Commissioner for Human Rights, said in `}<a parentName="p" {...{
        "href": "https://news.un.org/en/story/2020/07/1068391"
      }}>{`a statement`}</a>{`.`}</p>
    <p>{`Venezuela exports some gold to the refineries of its political and commercial allies, in countries such as Turkey, Iran, and the United Arab Emirates. Buyers in Europe and North America try to avoid tainted Venezuelan gold to protect their public image and to prevent a political fall-out.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The tainted gold leaves the refineries in glittering bars stamped with their logos, and is sold to international corporations that incorporate the precious metal in our phones, computers, cars, and other technologies.`}</p>
    </blockquote>
    <p>{`In 2019, the Trump administration went so far as to ban U.S. corporations from purchasing Venezuelan gold. But knowingly or unknowingly, Venezuelan gold may still run through their facilities after being laundered through Guyana, Suriname, and other gold-producing and transit countries in Latin America. From these countries, the tainted gold goes to international gold refiners. There, the gold is processed into glittering bars, stamped with the refiners’ logos, and sold to international corporations that incorporate the precious metal in our phones, computers, cars, and other technologies.`}</p>
    <p>{`International prohibitions and sanctions have pushed the Venezuelan gold trade further into the hands of criminal actors, increased illicit revenues, and made it very hard to trace the origin, according to experts and Venezuelans involved in the gold trade. “The American government has to put together a strategy, because the more they close the door, the profit margin is greater for `}{`[the Maduro government]`}{`,” one of the traffickers says.`}</p>
    <hr></hr>
    <p>{`One previously unknown route over which Venezuelan gold is trafficked is through Guyana. The former British colony is both a transit and destination country, where traffickers and international gold traders funnel the illegal gold into Guyana’s supply chain with false paperwork.`}</p>
    <p>{`The gold enters Guyana by land, by river, and by air. Small quantities are moved by Venezuelan refugees that arrive from mining regions in Bolívar state and bring a few grams of gold with them instead of cash savings.`}</p>
    <p>{`One Venezuelan woman claims that at the boat terminal of San Félix in southern Venezuela, the Venezuelan National Guard humiliates both men and women by forcing them to strip naked, bend over, and jump while squatting, to check for hidden bags with a few grams of gold. Many Venezuelan women, often refugees and single mothers, end up being sexually exploited in Guyana’s mining regions, where they are paid in gold.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "64.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQC/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAWN3kipJ/8QAGRABAQEBAQEAAAAAAAAAAAAAAgEDBBIU/9oACAEBAAEFAvp9URy8+njPhwDJMNxAWf8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHRAAAQMFAQAAAAAAAAAAAAAAAAECERITITFxgv/aAAgBAQAGPwJqpyB9O4xnZFDl4XHbkT0hliH/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhQTHw/9oACAEBAAE/ITGNLd3cs4jUAncXtMm5EYORAPhhxSilp7P/2gAMAwEAAgADAAAAEGc//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAEx/9oACAEDAQE/ENR//8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxBc5Lf/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMUFRYYGR/9oACAEBAAE/EGJkHjlYTioUUSKO+TwVfuIUl3st1AWPdvAMve4AeyjoEnyojGgFcts//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/6c623ac12011f74ecba59b4180ac3fc5/e93cc/Guyana-51.webp 300w", "/static/6c623ac12011f74ecba59b4180ac3fc5/b0544/Guyana-51.webp 600w", "/static/6c623ac12011f74ecba59b4180ac3fc5/68fc1/Guyana-51.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/6c623ac12011f74ecba59b4180ac3fc5/73b64/Guyana-51.jpg 300w", "/static/6c623ac12011f74ecba59b4180ac3fc5/3ad8d/Guyana-51.jpg 600w", "/static/6c623ac12011f74ecba59b4180ac3fc5/6c738/Guyana-51.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/6c623ac12011f74ecba59b4180ac3fc5/6c738/Guyana-51.jpg",
            "alt": "Venezuelan refugees arrive from mining regions in Bolívar state and bring a few grams of gold with them instead of cash savings.",
            "title": "Venezuelan refugees arrive from mining regions in Bolívar state and bring a few grams of gold with them instead of cash savings.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Venezuelan refugees arrive from mining regions in Bolívar state and bring a few grams of gold with them instead of cash savings.`}</em></p>
    <p>{`But the amounts brought by refugees and migrants do not compete with the bigger players in the market, who move hundreds of kilograms of tainted gold.`}</p>
    <p>{`A Guyanese gold dealer, who agreed to an interview on the condition of anonymity, admits to buying Venezuelan gold, and also claims that it’s brought into Guyana on clandestine jungle airstrips. According to the dealer, small planes fly from Venezuela to border areas in Guyana twice a month, carrying about 200 kilograms of gold on each flight. “We did come across planes and illegal airstrips in Guyana,” says Sam Sittlington, former advisor to Guyana’s Special Organized Crime Unit (SOCU).`}</p>
    <Video url="https://youtube.com/embed/667YSC2CFRc" mdxType="Video" />
    <p>{`Since the beginning of the economic and humanitarian crisis in Venezuela in 2013, hyperinflation in the country has spiraled out of control. Cash-strapped Venezuelan traders offer the gold at discount prices, sometimes at 40 percent under the market rate, to obtain foreign currencies. “There is no paperwork that comes with the gold,” says the
Guyanese gold dealer. “They’re so hungry for the U.S. dollar, and that is why they can afford to give 200 kilos on credit.”`}</p>
    <p>{`Traffickers also bring Venezuelan gold into Guyana by way of Brazil. The Guyanese dealer claims that until recently, about 150 kilograms a week were trafficked into Guyana through the border down of Lethem, and then put on direct flights to Georgetown, the capital city on the coast.`}</p>
    <p>{`Cash is taken back across the border into Venezuela or Brazil by couriers, according to various sources, including a foreign intelligence officer and the Guyanese gold trader, who both requested anonymity to speak freely. The border-hopping cash couriers carry millions in U.S. dollars, British Pounds, or Euros at a time. “Even if customs seized the money, they would return it back to the couriers within a couple of days,” Sittlington says.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“There is no paperwork that comes with gold," says the guyanese gold dealer`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAGCtap01CcL/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxESIf/aAAgBAQABBQLIGNcDPnnFuuUcZ//EABcRAQADAAAAAAAAAAAAAAAAAAABERL/2gAIAQMBAT8BpmH/xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAgEBPwFX/8QAHRAAAgEEAwAAAAAAAAAAAAAAAAERAhAhMUFhof/aAAgBAQAGPwJPhm4kw/ClRo6t/8QAGhAAAgMBAQAAAAAAAAAAAAAAAREAITFBYf/aAAgBAQABPyErYsptj2vYQCkIswfuZYQZNern/9oADAMBAAIAAwAAABAUP//EABgRAAIDAAAAAAAAAAAAAAAAAAAhARFh/9oACAEDAQE/EEdGEH//xAAYEQACAwAAAAAAAAAAAAAAAAAAEQExUf/aAAgBAgEBPxBObHo//8QAGxABAQEAAwEBAAAAAAAAAAAAAREAIUFRMYH/2gAIAQEAAT8QqS3+vPmTgvCmo8wgMoATcziFQFZkeSaO73rEU+N//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/95957ecebc70fe9cbd1343795481a182/e93cc/IMG_3791.webp 300w", "/static/95957ecebc70fe9cbd1343795481a182/b0544/IMG_3791.webp 600w", "/static/95957ecebc70fe9cbd1343795481a182/68fc1/IMG_3791.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/95957ecebc70fe9cbd1343795481a182/73b64/IMG_3791.jpg 300w", "/static/95957ecebc70fe9cbd1343795481a182/3ad8d/IMG_3791.jpg 600w", "/static/95957ecebc70fe9cbd1343795481a182/6c738/IMG_3791.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/95957ecebc70fe9cbd1343795481a182/6c738/IMG_3791.jpg",
            "alt": "Tens of kilos of gold have been trafficked into Guyana, a week, through border town Lethem.",
            "title": "Tens of kilos of gold have been trafficked into Guyana, a week, through border town Lethem.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Tens of kilos of gold have been trafficked into Guyana, a week, through border town Lethem.`}</em></p>
    <p>{`The couriers on the Brazilian border, often young students from Boa Vista, bring cash into Brazil as many as three times a week, according to the foreign intelligence officer. “They use very young guys and they pay $50 USD to bring it,” he says.`}</p>
    <p>{`The total amount of Venezuelan gold moving through Guyana is unknown, but it’s clear that a portion ends up in the hands of some of Guyana’s main gold exporters, according to high-ranking sources working for the state, who spoke on the condition of anonymity. One mining expert, who has been aware of Venezuelan gold arriving in Guyana since 2018, believes that all of Guyana’s gold exporters are involved. “It's not difficult to transport from Venezuela to Georgetown,"
he says.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“We have been receiving reports that we have dealers purchasing gold coming in from Venezuela,” says Eondrene Thompson, the Guyana Gold Board (GGB) general manager.`}</p>
    </blockquote>
    <p>{`The Guyana Gold Board, which oversees the refining and export of gold in the country, is aware that Venezuelan gold may be leaching into the country's supply chain, but so far they have not been able to put a stop to it. “We have been receiving reports that we have dealers purchasing gold coming in from Venezuela,” says Eondrene Thompson, the Guyana
Gold Board (GGB) general manager. She cautioned that the board has no physical evidence to prove that Venezuelan gold is trafficked into Guyana.`}</p>
    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFudpxIRYF//8QAGRAAAwEBAQAAAAAAAAAAAAAAAQIDEgAR/9oACAEBAAEFApbC2Ka85CZtnYM17//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AYf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGn/8QAHBAAAQQDAQAAAAAAAAAAAAAAAAERITEQEiKR/9oACAEBAAY/ArcWJLNHOlX3H//EABoQAQEBAQADAAAAAAAAAAAAAAERADEhQVH/2gAIAQEAAT8hAbJ4fMKjxp9slDfPdJqY5Xx3/9oADAMBAAIAAwAAABBvz//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/ECP/xAAXEQEAAwAAAAAAAAAAAAAAAAAAAREx/9oACAECAQE/EMKh/8QAHhABAAICAQUAAAAAAAAAAAAAAQARIUFhMVFxkdH/2gAIAQEAAT8QEbIK5rvzDNj63YjfJGdGNLAAdMmu2oKzJQ2D2QGa8qfJ/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/9348f0e45f96007f7e21b32be866daff/e93cc/Guyana-10.webp 300w", "/static/9348f0e45f96007f7e21b32be866daff/b0544/Guyana-10.webp 600w", "/static/9348f0e45f96007f7e21b32be866daff/68fc1/Guyana-10.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/9348f0e45f96007f7e21b32be866daff/73b64/Guyana-10.jpg 300w", "/static/9348f0e45f96007f7e21b32be866daff/3ad8d/Guyana-10.jpg 600w", "/static/9348f0e45f96007f7e21b32be866daff/6c738/Guyana-10.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9348f0e45f96007f7e21b32be866daff/6c738/Guyana-10.jpg",
              "alt": "It's not difficult to transport from Venezuela to Georgetown",
              "title": "It's not difficult to transport from Venezuela to Georgetown",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`It's not difficult to transport from Venezuela to Georgetown`}</em></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwAEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAHjoqwzTR//xAAZEAADAAMAAAAAAAAAAAAAAAAAARECIjH/2gAIAQEAAQUCFUQx1fXT/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BiP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAMBAQAAAAAAAAAAAAAAAAAQESFx/9oACAEBAAY/AjqkNX//xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMVFhgf/aAAgBAQABPyEFdX5KHAajxmUpD7MIi2WFCp//2gAMAwEAAgADAAAAEEQf/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/EMpD/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EFy1v//EABsQAQEBAQADAQAAAAAAAAAAAAERADEhQWFx/9oACAEBAAE/EAAL4YWR6Ev5kWmH2hgNUnRemTqA83LggZzf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/d6b72f3dde8fdd4e266700b7317fc982/e93cc/Guyana-27.webp 300w", "/static/d6b72f3dde8fdd4e266700b7317fc982/b0544/Guyana-27.webp 600w", "/static/d6b72f3dde8fdd4e266700b7317fc982/68fc1/Guyana-27.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/d6b72f3dde8fdd4e266700b7317fc982/73b64/Guyana-27.jpg 300w", "/static/d6b72f3dde8fdd4e266700b7317fc982/3ad8d/Guyana-27.jpg 600w", "/static/d6b72f3dde8fdd4e266700b7317fc982/6c738/Guyana-27.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d6b72f3dde8fdd4e266700b7317fc982/6c738/Guyana-27.jpg",
              "alt": "Guyana 27",
              "title": "Guyana 27",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
    </Gallery>
    <p>{`But quantities of gold mined in Guyana differ from how much is actually declared and traded, according to Newell Dennison, head of the Guyana Geology and Mines Commission (GGMC). “The amount of gold that is declared to the GGB does not reconcile easily with the amount of gold that our officers from GGMC ascertain as being produced from the
operations on the ground,” he says. “We have had situations where the difference is troubling to us.”`}</p>
    <p>{`Gold production in Guyana’s small-scale gold mines contracted in 2019, according to multiple sources, due to issues with access to fuel and decreasing public investments in the sector. Yet gold declarations by dealers and exporters rose in 2019, which could represent gold coming from illegal Guyanese mines, or gold trafficked into Guyana from other countries.`}</p>
    <p>{`In the first five months of 2020, Guyanese gold dealers declared 940 kilograms more than the same period in 2019—a nearly 27 percent increase during the COVID-19 pandemic. The GGB has also found that miners register hotel addresses in Guyana’s interior, rather than mines, as places where they supposedly sourced their gold.`}</p>
    <Sidenote side="left" mdxType="Sidenote">
	Gold dealers declared 940 kilograms more than the same period in 2019—a nearly 27 percent increase during the COVID-19 pandemic.
    </Sidenote>
    <iframe height="480" src="https://datawrapper.dwcdn.net/Dg0r7/2/"></iframe>
    <blockquote>
      <p parentName="blockquote">{`Argor-Heraeus, for example, sells their gold to apple, Samsung, Microsoft, Phillip Morris, Ford and Tesla`}</p>
    </blockquote>
    <p>{`Sources from within the sector, including gold traders, traffickers, and law enforcement, allege that at least two of Guyana’s licensed gold exporters—El Dorado Trading and Mohamed’s Trading, the country’s two largest private gold dealers—have purchased illicit Venezuelan gold. El Dorado Trading exports gold to Guardian International Gold Corp in
Canada, Tony Goetz in Belgium, and Argor-Heraeus in Switzerland. Mohamed’s Trading exports to Zee Gold DMCC in the United Arab Emirates.`}</p>
    <p>{`Other companies, including several recognizable multinationals, then purchase the gold directly from these refineries. Argor-Heraeus, for example, sells their gold to Apple, Samsung, Microsoft, Phillip Morris, Ford, and Tesla. Tony Goetz’s clients include Sony, Starbucks, and Hewlett-Packard.`}</p>
    <p>{`Sources familiar with the companies’ practices claim that Guyanese miners working in Venezuela bring the gold to Guyana and sell it to El Dorado Trading, and that both companies occasionally buy gold directly from Venezuelan criminals on the border, referred to as sindicatos or malandros.`}</p>
    <p>{`El Dorado Trading denied allegations that the company has purchased Venezuelan gold, and Guardian International, Tony Goetz, and Argor-Heraeus all claimed that they had no reason to believe El Dorado had sold them Venezuelan gold. “Our own standards and the legal standards in Switzerland are amongst the strictest in the world and we are absolutely determined to follow them,” Argos-Heraeus’s head of communications wrote in an email.`}</p>
    <p>{`Mohamed’s Trading did not respond to requests for comment.`}</p>
    <p>{`One Venezuelan gold trafficker alleges that Mohammed’s Trading, owned by Nazar Mohamed, is buying gold from the Venezuelan regime’s military leadership. “Mohamed, that one, he is the tough one over there `}{`[in Guyana]`}{`,” he says. “It’s him who is throwing dollars very hard at these people.” The trafficker claims that the military buys from the National Liberation Army (ELN) guerrillas near a mine called Corre Gente, in the Venezuelan Bolívar state. “There is an airstrip there,” he says.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“From there, `}{`[the gold]`}{` illegally leaves for Guyana, they hand it over and bring dollars back.”`}</p>
    </blockquote>
    <p>{`A source in a foreign intelligence service claims that Mohamed is the “main player” involved in trafficking illicit goods from Venezuela into Guyana, and then abroad. “`}{`[Mohamed]`}{` is buying Venezuelan gold, definitely he is the biggest buyer, no doubt about it. He is also trafficking cocaine and firearms from Venezuela,” explains the foreign intelligence officer, adding that the weapons are sold to clients in Suriname and the cocaine leaves for international markets.`}</p>
    <p>{`The third party gold dealer also claims that the leadership at Mohamed and El Dorado Trading know about the companies’ involvement with Venezuelan gold. “They’re fully aware, because they have to put up the money up front for the purchase of U.S. currency in Guyanese dollars to pay for the gold, and they themselves will have their security sometimes fly into the bush, into the interior.” The gold dealer claims to have sold Venezuelan gold on one occasion to El Dorado Trading.`}</p>
    <Video url="https://youtube.com/embed/lpQ-zLmw6pE" mdxType="Video" />
    <p>{`The leadership at both companies appear to be connected to political elites in Guyana; both allegedly donated funds to both the ruling and former government party, and Mohamed reportedly donated an armored car to the new president, Irfaan Ali.`}</p>
    <p>{`Tamesh Jagmohan, El Dorado Trading’s director, suggested that the allegations against the company “emanate from persons with ulterior motives. It is not uncommon in Guyana for certain private/public groupings to combine efforts to attack others for competitive,financial or political purposes.”`}</p>
    <p>{`Regardless of the gold smuggling allegations against El Dorado Trading, Jagmohan himself has been investigated by the FBI for gold smuggling to the U.S. and he and his company are suspected of having laundered money for the terrorist organization Hezbollah. Jagmohan was stopped at the Miami airport in 2012 with 27 bars of gold he failed to declare. According to someone with knowledge of the investigation, he was fined $40,000 USD and had his visa revoked.`}</p>
    <p>{`According to Sittlington, the SOCU has also found that individuals heavily adorned with gold jewelry smuggle gold on flights to markets in North America, such as Miami, and that bags containing gold have bypassed airport security: “In other words, there is corruption at the airport,” Sittlington adds. According to one government source, in late
2019, officials halted an El Dorado shipment of about 125 kilograms of gold ready to take-off to Barbados. The shipment aroused suspicions after officials identified irregularities with the seal and noticed that it was scheduled to leave from an airport that is not used for official gold transports.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Individuals heavily adorned with gold jewelry smuggle gold on flights to markets in North America, such as Miami, and that bags containing gold have bypassed airport security:`}</p>
    </blockquote>
    <p>{`Some of the gold is transited through Suriname, then brought to The Netherlands, sometimes on commercial KLM flights, before it’s taken to Belgium. KLM could not confirm or deny that it had transported gold shipments in the past due to the "sensitive nature of the shipments," but added that it decided in July 2019 to no longer transport Venezuelan
gold.`}</p>
    <p>{`Besides trafficking Venezuelan gold, major gold buyers make hefty profits by using a different exchange rate for the U.S. Dollar than banks and the state, winning a few dollars on each gram of gold. More money is made through tax evasion. Miners sell their gold plus 8.5 percent in taxes, which El Dorado Trading and Mohamed’s Trading should pass on to the state. Oftentimes this does not happen, and the taxes paid by small-scale miners are pocketed by Guyana’s biggest gold exporters.`}</p>
    <p>{`Despite officials’ knowledge of trafficking and tax evasion, they’ve been unable to put a stop to it. The foreign intelligence officer in Guyana complains that no legal cases can be built because of the deep corruption in Guyanese law enforcement. “The police force is part of the problem,” he says, “not part of the solution.”`}</p>
    <hr></hr>
    <p>{`Not all of the gold mined or trafficked into Guyana is directly sold to international refineries. The lawlessness and uncontrolled borders of the northern Amazon mean that much of that gold goes where taxes are lowest and foreign currencies are paid.`}</p>
    <Video url="https://youtube.com/embed/veX0nCa5ARQ" mdxType="Video" />
    <p>{`On the Guyanese banks of the Corentyne River lies a small town called Corriverton. Frequent boats ferry passengers and goods across the river to Suriname, offering ample opportunities to traffickers. Showtime*, a local criminal entrepreneur with a career in gold mining and trafficking, explains how contraband economies work in a nutshell. “All I know is to go to the interior and work, bring home the gold for my family,” Showtime says. “Now I realize that if I take that gold to Suriname, I’m going to get double the amount to look after my family. So I’m going to try to be creative enough to take my gold over there.”`}</p>
    <blockquote>
      <p parentName="blockquote">{`"If I take that gold to Suriname, I’m going to get double the amount to look after my family. So I’m going to try to be creative enough to take my gold over there.”`}</p>
    </blockquote>
    <p>{`He is not alone. "The belief is that conservatively 35 to 45 percent of our gold production is smuggled out of the country, primarily to Suriname,” says Gabriel Lall, the former chairman of the GGB. “With the supposed Venezuelan gold surfacing `}{`[in Guyana]`}{`, you add to that."`}</p>
    <p>{`Until recently, Suriname was the most attractive country in the region to sell the precious metal: minimal tax and royalty collection on gold brought in miners from all across the Guiana Shield. Additionally, Surinamese gold shops often paid in U.S. dollars or Euros, while most—especially the smaller—Guyanese gold shops only offer GYD.`}</p>
    <p>{`As the price of gold shot up over the last two years, gold shops in Suriname’s capital city of Paramaribo increased their revenues. Before the COVID-19 pandemic, some smaller and independent gold shops in the center of Paramaribo processed one or two kilograms a day and mainly bought small quantities from inland miners. But there were, and still are, bigger players on the market. One of the larger gold buyers with an export license claimed to have bought up to 50 kilograms a day, a market value of more than $3 million USD, from gold shops throughout the capital.`}</p>
    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAQUAAAAAAAAAAAAAAAAAAAECAwQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABylrTAND/xAAbEAABBAMAAAAAAAAAAAAAAAACAAEDERASIv/aAAgBAQABBQLaxaJzHtsBKQK1/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAAREAEAJBUf/aAAgBAQAGPwIPXYconSBr/8QAGxAAAwACAwAAAAAAAAAAAAAAAAERMUEhUaH/2gAIAQEAAT8haIRcArEcejSfjsyJ9rwo/9oADAMBAAIAAwAAABCT7//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxBkf//EAB0QAQADAAEFAAAAAAAAAAAAAAEAETEhQVFhcaH/2gAIAQEAAT8QQzO7NHavsSoyOhfUpkQVVo8RKbGvECC7F3Kquq8s/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/8a9bacc3b3e21b7894c3dd14d14d489b/e93cc/DSC07015.webp 300w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/b0544/DSC07015.webp 600w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/68fc1/DSC07015.webp 1200w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/0b978/DSC07015.webp 1400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/8a9bacc3b3e21b7894c3dd14d14d489b/73b64/DSC07015.jpg 300w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/3ad8d/DSC07015.jpg 600w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/6c738/DSC07015.jpg 1200w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/22cab/DSC07015.jpg 1400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/8a9bacc3b3e21b7894c3dd14d14d489b/6c738/DSC07015.jpg",
              "alt": "As the price of gold shot up over the last two years, gold shops in Suriname’s capital city of Paramaribo increased their revenues.",
              "title": "As the price of gold shot up over the last two years, gold shops in Suriname’s capital city of Paramaribo increased their revenues.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`As the price of gold shot up over the last two years, gold shops in Suriname’s capital city of Paramaribo increased their revenues.`}</em></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHJcESIP//EABkQAAIDAQAAAAAAAAAAAAAAAAERAAIQMf/aAAgBAQABBQIWLv3EIp//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAACAwAAAAAAAAAAAAAAAAAAMREgMv/aAAgBAQAGPwJm5oz/xAAZEAADAQEBAAAAAAAAAAAAAAAAARFRMUH/2gAIAQEAAT8hWlytlEwV4VrjIU8g2o//2gAMAwEAAgADAAAAEDc//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EI1//8QAFREBAQAAAAAAAAAAAAAAAAAAECH/2gAIAQIBAT8Qp//EAB0QAQEAAgEFAAAAAAAAAAAAAAERAHFBITFhkbH/2gAIAQEAAT8QHlv17Y1zkVRlF/TjAUSbwNiCeTJKLRwSK9Z//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/b146ad0313d0c143ce3c60aec6fa796e/e93cc/DSC07035.webp 300w", "/static/b146ad0313d0c143ce3c60aec6fa796e/b0544/DSC07035.webp 600w", "/static/b146ad0313d0c143ce3c60aec6fa796e/68fc1/DSC07035.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/b146ad0313d0c143ce3c60aec6fa796e/73b64/DSC07035.jpg 300w", "/static/b146ad0313d0c143ce3c60aec6fa796e/3ad8d/DSC07035.jpg 600w", "/static/b146ad0313d0c143ce3c60aec6fa796e/6c738/DSC07035.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b146ad0313d0c143ce3c60aec6fa796e/6c738/DSC07035.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHNUZzOaK//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQISIQP/2gAIAQEAAQUC2TXKpYkR0aTP/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEhMf/aAAgBAwEBPwGMU//EABYRAAMAAAAAAAAAAAAAAAAAAAIQMf/aAAgBAgEBPwEqv//EABoQAAICAwAAAAAAAAAAAAAAAAARAjEBECH/2gAIAQEABj8CVDlLfcFI/8QAGhABAAMAAwAAAAAAAAAAAAAAAQARITFBYf/aAAgBAQABPyG8IMxUiO+YKLGpTYL7EsPTJ//aAAwDAQACAAMAAAAQ9N//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAEDAQE/ECHFs//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EEs//8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFBYXH/2gAIAQEAAT8QEigXnKwDZJsQr1uBYAg4Q3EN6DZ2J6y+oeRgw6rs/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1955505b6f7aa1de89f88179df623995/e93cc/DSC07454.webp 300w", "/static/1955505b6f7aa1de89f88179df623995/b0544/DSC07454.webp 600w", "/static/1955505b6f7aa1de89f88179df623995/68fc1/DSC07454.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1955505b6f7aa1de89f88179df623995/73b64/DSC07454.jpg 300w", "/static/1955505b6f7aa1de89f88179df623995/3ad8d/DSC07454.jpg 600w", "/static/1955505b6f7aa1de89f88179df623995/6c738/DSC07454.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1955505b6f7aa1de89f88179df623995/6c738/DSC07454.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHCeXGkQT//xAAbEAACAgMBAAAAAAAAAAAAAAAAARITAhEhMf/aAAgBAQABBQLwrzjxEmbdZ//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AYf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAAAEAEREiFR/9oACAEBAAY/AjKtHVK//8QAHBAAAwABBQAAAAAAAAAAAAAAAAERITFBUXGR/9oACAEBAAE/IX6EzJgfAMW3cE6rcND/2gAMAwEAAgADAAAAEOjP/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EDC//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EIx//8QAGxABAQEAAwEBAAAAAAAAAAAAAREAMUFRYaH/2gAIAQEAAT8QpnJ+spPZN69maSVKtmHMlPCuJXcoMr9nOSpv/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/2f0e14519eea91dcb0a5756fbee93ae6/e93cc/DSC07458.webp 300w", "/static/2f0e14519eea91dcb0a5756fbee93ae6/b0544/DSC07458.webp 600w", "/static/2f0e14519eea91dcb0a5756fbee93ae6/68fc1/DSC07458.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/2f0e14519eea91dcb0a5756fbee93ae6/73b64/DSC07458.jpg 300w", "/static/2f0e14519eea91dcb0a5756fbee93ae6/3ad8d/DSC07458.jpg 600w", "/static/2f0e14519eea91dcb0a5756fbee93ae6/6c738/DSC07458.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2f0e14519eea91dcb0a5756fbee93ae6/6c738/DSC07458.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHHeLRIUr//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIQEhH/2gAIAQEAAQUCitGUMi+Dr//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAQADAAAAAAAAAAAAAAAAAAEAICH/2gAIAQEABj8CCY1//8QAGxABAAICAwAAAAAAAAAAAAAAAQARECExQVH/2gAIAQEAAT8hsAgPMamzXvcW461EouP/2gAMAwEAAgADAAAAENjP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QRn//xAAbEAEAAwEAAwAAAAAAAAAAAAABABEhMRBB0f/aAAgBAQABPxBAhLTN/E3gr4jjBoMAnPE7STE4168f/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/f17a7c0bffa837263e594323a3f7ccc5/e93cc/DSC07461.webp 300w", "/static/f17a7c0bffa837263e594323a3f7ccc5/b0544/DSC07461.webp 600w", "/static/f17a7c0bffa837263e594323a3f7ccc5/68fc1/DSC07461.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/f17a7c0bffa837263e594323a3f7ccc5/73b64/DSC07461.jpg 300w", "/static/f17a7c0bffa837263e594323a3f7ccc5/3ad8d/DSC07461.jpg 600w", "/static/f17a7c0bffa837263e594323a3f7ccc5/6c738/DSC07461.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f17a7c0bffa837263e594323a3f7ccc5/6c738/DSC07461.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAQADBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeMZoxH/xAAZEAADAQEBAAAAAAAAAAAAAAAAAQIhERL/2gAIAQEAAQUCmqZUtPBPh6Hp/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAABEgIf/aAAgBAQAGPwJGz//EABkQAQEAAwEAAAAAAAAAAAAAAAEAETFBUf/aAAgBAQABPyEDDvkQNpDvMustcpdL/9oADAMBAAIAAwAAABDwD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAECAQE/EKf/xAAZEAEBAQEBAQAAAAAAAAAAAAABEQAhMVH/2gAIAQEAAT8QRWRQB7uZMeWwyMo54XEaPzQYVare7ueKb//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/8cb766c29b50f66e3c9920965dc82c02/e93cc/DSC07475.webp 300w", "/static/8cb766c29b50f66e3c9920965dc82c02/b0544/DSC07475.webp 600w", "/static/8cb766c29b50f66e3c9920965dc82c02/68fc1/DSC07475.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/8cb766c29b50f66e3c9920965dc82c02/73b64/DSC07475.jpg 300w", "/static/8cb766c29b50f66e3c9920965dc82c02/3ad8d/DSC07475.jpg 600w", "/static/8cb766c29b50f66e3c9920965dc82c02/6c738/DSC07475.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/8cb766c29b50f66e3c9920965dc82c02/6c738/DSC07475.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
    </Gallery>
    <p>{`Ourominas is one of the bigger gold buying chains in Suriname, with multiple storefronts in Paramaribo. Buyers like Ourominas aren't required to ask sellers for any sort of certification or papers of origin. In one of their shops in Paramaribo, an employee stated that the company does not demand for any paperwork to buy gold and therefore it is impossible to know if it was mined domestically or abroad.`}</p>
    <p>{`“If it’s also Guyanese gold, we just don’t know that,” the employee says. At least 10 other gold shops in Paramaribo affirmed that the stores had no requirement nor process to find out where the gold has been mined. But some employees admitted to knowingly buying gold from neighbouring French-Guiana, Guyana, and even from the conflict-ridden Venezuelan mines.`}</p>
    <p>{`One of the burners in a major gold shop, whose job is to burn the mercury off of the amalgams of gold and mercury that come from the mines, commented that a Venezuelan person enters once or twice a month to dispatch up to 30 kilograms of gold, but declined to provide any more information. A Venezuelan gold trafficker consulted for this report claims that traffickers use Suriname to launder illegally mined gold into the country’s legal supply chain.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAeDaYZtJ/8QAGBAAAgMAAAAAAAAAAAAAAAAAAQIAESD/2gAIAQEAAQUCqOqgY//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAQEBAQAAAAAAAAAAAAAAAAECACD/2gAIAQEABj8CwzY8/wD/xAAaEAACAwEBAAAAAAAAAAAAAAABEQAQQSEx/9oACAEBAAE/IRD2LgHlPIRx1//aAAwDAQACAAMAAAAQAw//xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8Q0Cf/xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8QRUdn/8QAGhABAAIDAQAAAAAAAAAAAAAAAQARIVFxMf/aAAgBAQABPxBHLg3H8ftg9XLl7cK5BXpKJ//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/23fdc230a436324b2e0c7bc923ec548e/e93cc/DSC07464.webp 300w", "/static/23fdc230a436324b2e0c7bc923ec548e/b0544/DSC07464.webp 600w", "/static/23fdc230a436324b2e0c7bc923ec548e/68fc1/DSC07464.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/23fdc230a436324b2e0c7bc923ec548e/73b64/DSC07464.jpg 300w", "/static/23fdc230a436324b2e0c7bc923ec548e/3ad8d/DSC07464.jpg 600w", "/static/23fdc230a436324b2e0c7bc923ec548e/6c738/DSC07464.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/23fdc230a436324b2e0c7bc923ec548e/6c738/DSC07464.jpg",
            "alt": "The job in a major gold shop is to burn the mercury off of the amalgams of gold and mercury that come from the mines",
            "title": "The job in a major gold shop is to burn the mercury off of the amalgams of gold and mercury that come from the mines",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span><em parentName="p">{`The job in a major gold shop is to burn the mercury off of the amalgams of gold and mercury that come from the mines`}</em></p>
    <p>{`Some of the gold shops sell gold to the international trader Kaloti Precious Metals, which has a refinery in Dubai, but the multinational also has a stake in the Kaloti Suriname Mint House (KSMH).`}</p>
    <p>{`The KSMH, launched in 2015, smelts and analyses the gold, and then calculates the royalties that must be paid to Suriname's government. Most of the gold coming from Suriname’s small- and medium-scale mines has to pass through the KSMH, a joint venture between Kaloti's Dubai refinery, Surinamese business owners, and the state. Kaloti Precious Metals owns a majority stake in the operation, while Surinamese business owners own 30 percent, and the state owns just 10 percent.`}</p>
    <p>{`The KSMH never actually owns the precious metal that comes through its doors, according to Ryan Tjon, the director of the KSMH. But Tjon confirms that Kaloti Dubai is one of the buyers of Surinamese gold. Kaloti considered building the Mint House in other countries in the Guiana Shield region, but ultimately chose Suriname due to its long-standing involvement with the country's gold industry. Kaloti's Dubai refinery has imported gold from Suriname since the 1990s.`}</p>
    <p>{`The Mint House was supposed to turn Suriname into a "competitive bullion trading hub and center of excellence for the precious metals industry," `}<a parentName="p" {...{
        "href": "http://www.kalotipm.com/Media-Center-PressReleases-Details/7/KALOTI-OPENS-SURINAMES-FIRST-GOLD-REFINERY"
      }}>{`according to Munir Kaloti`}</a>{`, the chairman of Kaloti Precious Metals. The KSMH has a yearly processing capacity of 60,000 kilos of gold—much higher than Suriname’s national annual gold production. Its goal was always to process gold from all of the Guiana Shield countries, but the government failed to promote gold imports from its neighbors and the project has yet to turn a profit.`}</p>
    <p>{`In 2019, the KSMH processed between 15,000 and 20,000 kilograms of gold, according to Tjon. In theory, that's most of the gold that the country's twelve exporters bought from either small-scale mining operations or from the country's numerous gold shops. But in practice, there are no guarantees that the gold these license holders send to the refinery before exporting is actually mined in Suriname. “It would be very difficult to say where it came from,” Tjon says.`}</p>
    <p>{`The KSMH is in the position to deny gold if irregularities present themselves; for example, if unusual amounts of gold are moved through the Mint House. Nevertheless, this has not happened in the three years that Tjon has directed the company. The Mint House director is aware that gold trafficking is an issue, “but we are not the ones, the agency, to investigate where the gold actually comes from,” he says. “We are not a police officer.” Instead, the refinery relies on exporters to do their due diligence. If an exporter indicates that it’s Surinamese gold, the KMHS takes it in good faith, according to Tjon.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“We are not a police officer.” Instead, the refinery relies on exporters to do their due diligence`}</p>
    </blockquote>
    <p>{`A lack of oversight at every stage, from the smallest gold shop to the KSMH, has allowed traffickers to launder illegally mined gold into Suriname's legal supply chain. But one reason it’s so hard for authorities to crack down on the illicit trade is that, much like the mercury trade, the trafficking networks for illegally mined gold are always in flux.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUCAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABq0IJQ5EIf//EABsQAAIBBQAAAAAAAAAAAAAAAAABAgQRExQx/9oACAEBAAEFAo1UTahbMhIfD//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/Aaf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGH/8QAFxAAAwEAAAAAAAAAAAAAAAAAARAxIP/aAAgBAQAGPwKKDH//xAAaEAEBAAIDAAAAAAAAAAAAAAABABEhMWGR/9oACAEBAAE/IQBcvEHuWdekDENCzf/aAAwDAQACAAMAAAAQRP8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EIa//8QAFhEAAwAAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EHREf//EABsQAQADAAMBAAAAAAAAAAAAAAEAETEhUXFh/9oACAEBAAE/EGJHSXkrD7ieZcEnqaFsEGmrFDQoez//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/e290b5d854ae9416aa4900fa84abd977/e93cc/DSC07628.webp 300w", "/static/e290b5d854ae9416aa4900fa84abd977/b0544/DSC07628.webp 600w", "/static/e290b5d854ae9416aa4900fa84abd977/68fc1/DSC07628.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/e290b5d854ae9416aa4900fa84abd977/73b64/DSC07628.jpg 300w", "/static/e290b5d854ae9416aa4900fa84abd977/3ad8d/DSC07628.jpg 600w", "/static/e290b5d854ae9416aa4900fa84abd977/6c738/DSC07628.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/e290b5d854ae9416aa4900fa84abd977/6c738/DSC07628.jpg",
            "alt": "Paramaribo, the Surinamese capital is suffering from the economic crisis since early 2020.",
            "title": "Paramaribo, the Surinamese capital is suffering from the economic crisis since early 2020.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Paramaribo, the Surinamese capital is suffering from the economic crisis since early 2020.`}</em></p>
    <p>{`Suriname’s economy took a deep dive after the outgoing Desi Bouterse government raided the Central Bank in February and restricted foreign exchange rates, causing a steep surge in inflation and the economy to nearly collapse. As a consequence of the ongoing economic disaster, foreign currencies became scarce and the Surinamese Dollar quickly lost value.`}</p>
    <p>{`Some Paramaribo gold shops now only pay in Surinamese currency, according to one local gold miner, or charge an extra 10 percent for payments in USD. A more expensive exchange rate for the Euro or USD is applied in Suriname, leading to lower prices for gold, up to 20 percent less than the market price. Another trader claims that the recent changes have pushed the Surinamese gold trade back across the border. “Gold trafficking became attractive again in Suriname,” he says. “Our gold now goes to Guyana,” says another dealer, adding that those who are able to move gold across the border into Guyana can save thousands of U.S. dollars per kilogram.`}</p>
    <p>{`The new administration in Suriname, headed by President Chan Santokhi, the former chief of police, has promised to reform the country’s gold mining sector, but the country’s new vice president, Ronnie Brunswijk, is a former rebel leader and drug trafficker, and a major player in Suriname’s gold industry, running several mines in the country’s interior. While it’s not unusual for political elites to have a stake in Suriname’s gold mining sector, it remains to be seen if the country can stamp out the black market while those who profit from it are in power.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      